import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import { getYYYYMMDDFormatDate } from 'helpers/utils';
import React, { useState, useEffect, useRef } from 'react';
import {
  Col,
  Row,
  Card,
  ButtonGroup,
  ToggleButton,
  Form
} from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import UpsertBudgetOverview from '../budget-overviews/components/UpsertBudgetOverview';
import {
  activeColumns,
  endedColumns,
  upcomingColumns
} from './BudgetOverviewListingColumnTypes';
import AvazaWidget from './components/AvazaWidget';
import BudgetOverviewAdditionalDetails from './components/BudgetOverviewAdditionalDetails';
import GoalChart from './components/GoalChart';
import SpendChart from './components/SpendChart';

const BudgetOverviews = () => {
  const today = new Date();
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [toggleListingButtonValue, setToggleListingButtonValue] =
    useState('active');
  const [currentBudgetOverviewId, setCurrentBudgetOverviewId] = useState(null);
  const [showUpsertBudgetOverviewModal, setShowUpsertBudgetOverviewModal] =
    useState(false);
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const [date, setDate] = useState(today.setDate(today.getDate() - 1));
  const [networks, setNetworks] = useState([]);
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useState({
    network_id: '',
    manager_id: '',
    starred: 0,
    project_lead_id: '',
    temporary: ''
  });
  const [skipPageReset, setSkipPageReset] = useState(false);

  const LISTING_TYPE = {
    ACTIVE: 'active',
    ENDED: 'ended',
    UPCOMING: 'upcoming'
  };

  const MainTableRef = useRef();

  const loadListing = listing => {
    setApiCallInProcess(true);
    let starColumn = {
      accessor: 'is_starred',
      Header: () => null,
      headerProps: {
        className: 'bg-200',
        style: {
          position: 'sticky',
          left: '0',
          marginRight: '-100px',
          zIndex: 2
        }
      },
      cellProps: {
        style: {
          position: 'sticky',
          background: 'var(--falcon-card-bg)',
          left: '0',
          marginRight: '-100px'
        }
      },
      footerProps: {
        style: {
          position: 'sticky',
          left: '0',
          marginRight: '-100px',
          zIndex: 2
        }
      },
      canResize: false,
      Cell: ({ cell, row, state: { globalFilter }, setGlobalFilter }) => {
        return (
          <FontAwesomeIcon
            icon={row.original.is_starred ? 'star' : ['far', 'star']}
            className="text-warning cursor-pointer"
            onClick={() => {
              let formData = new FormData();
              formData.append('budget_overview_id', row.original.id);
              formData.append(
                'action',
                row.original.is_starred ? 'un-star' : 'star'
              );

              CallApi.post(
                `/dashboard/toggle-star-budget-overview`,
                formData
              ).then(json => {
                setData(old =>
                  old.map((tableRow, index) => {
                    if (index == row.index) {
                      return {
                        ...old[row.index],
                        is_starred: !tableRow.is_starred
                      };
                    } else {
                      return tableRow;
                    }
                  })
                );
                setGlobalFilter(globalFilter);
              });
            }}
          />
        );
      }
    };
    switch (listing.value) {
      case LISTING_TYPE.ACTIVE:
        setColumns([starColumn, ...activeColumns]);
        break;

      case LISTING_TYPE.ENDED:
        setColumns([starColumn, ...endedColumns]);
        break;

      case LISTING_TYPE.UPCOMING:
        setColumns([starColumn, ...upcomingColumns]);
        break;

      default:
        setColumns([starColumn, ...activeColumns]);
        break;
    }
    CallApi.get(listing.url).then(json => {
      if (json.status == 200) {
        const filterValue = MainTableRef.current?.globalFilter;
        setData(json.data);
        MainTableRef.current?.updateGlobalFilter(filterValue);
        setApiCallInProcess(false);
      }
    });
  };

  const listingToggleButtons = [
    {
      value: LISTING_TYPE.ACTIVE,
      label: 'Active',
      url: `/dashboard/active-budget-overviews?network=${
        filters.network_id
      }&manager=${filters.manager_id}&date=${getYYYYMMDDFormatDate(
        date
      )}&starred=${filters.starred}&project_lead=${
        filters.project_lead_id
      }&temporary=${filters.temporary}`,
      variant: 'outline-primary'
    },
    // {
    //   value: LISTING_TYPE.ENDED,
    //   label: 'Ended',
    //   url: `/dashboard/ended-budget-overviews?network=${filters.network_id}&manager=${filters.manager_id}&starred=${filters.starred}&project_lead=${filters.project_lead_id}&temporary=${filters.temporary}`,
    //   variant: 'outline-primary'
    // },
    {
      value: LISTING_TYPE.UPCOMING,
      label: 'Upcoming',
      url: `/dashboard/upcoming-budget-overviews?network=${filters.network_id}&manager=${filters.manager_id}&starred=${filters.starred}&project_lead=${filters.project_lead_id}&temporary=${filters.temporary}`,
      variant: 'outline-primary'
    }
  ];

  const getNetworks = () => {
    CallApi.get('/networks', false).then(json =>
      json.status == 200 ? setNetworks(json.data) : ''
    );
  };

  const getUsers = () => {
    CallApi.get('/users?filter[role.name]=admin,user', false).then(json =>
      json.status == 200 ? setUsers(json.data) : ''
    );
  };

  useEffect(() => {
    loadListing(
      listingToggleButtons.find(button =>
        button.value === toggleListingButtonValue ? button.url : null
      )
    );

    if (networks.length == 0) {
      getNetworks();
    }

    if (users.length == 0) {
      getUsers();
    }
  }, [toggleListingButtonValue, date, filters]);

  // Create a function that will render our row sub components
  const renderRowSubComponent = ({ row }) => (
    <Row className="bg-light py-2">
      <Col xs={2} md={3}>
        <BudgetOverviewAdditionalDetails
          budgetOverviewId={row.original.id}
          setCurrentBudgetOverviewId={setCurrentBudgetOverviewId}
          setShowUpsertBudgetOverviewModal={setShowUpsertBudgetOverviewModal}
        />
      </Col>
      <Col xs={2} md={3}>
        <SpendChart budgetOverviewId={row.original.id} />
      </Col>
      <Col xs={2} md={3}>
        <GoalChart budgetOverviewId={row.original.id} />
      </Col>
      <Col xs={2} md={3}>
        <AvazaWidget clientId={row.original.client_id} />
      </Col>
    </Row>
  );

  const SelectedRowsModifier = ({ setFilter, selectedFlatRows }) => (
    <Form.Select
      size="sm"
      onChange={e => setFilters({ ...filters, starred: e.target.value })}
      value={filters.starred || ''}
    >
      <option value="0"> All</option>
      <option value="1"> Starred </option>
    </Form.Select>
  );

  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <AdvanceTableWrapper
                ref={MainTableRef}
                columns={columns}
                data={data}
                sortable
                stickSelectionColumn={true}
                resizable
                pagination
                renderRowSubComponent={renderRowSubComponent}
                skipPageReset={skipPageReset}
                useTableFooter={true}
              >
                <Row className="align-items-end g-2 row mb-3">
                  <Col xs={12} md={'auto'}>
                    <AdvanceTableSearchBox table />
                  </Col>
                  <Col xs={6} md="auto" className="ms-md-auto">
                    <Form.Select
                      size="sm"
                      onChange={e =>
                        setFilters({ ...filters, temporary: e.target.value })
                      }
                    >
                      <option value="">Budget Type (All)</option>
                      <option value="1">Temporary</option>
                      <option value="0">Recurring</option>
                    </Form.Select>
                  </Col>
                  <Col xs={6} md="auto">
                    <SelectedRowsModifier table />
                  </Col>
                  <Col xs={6} md="auto">
                    <Form.Select
                      size="sm"
                      onChange={e =>
                        setFilters({ ...filters, network_id: e.target.value })
                      }
                    >
                      <option value="">All Networks</option>
                      {networks.map(network => (
                        <option key={network.id} value={network.id}>
                          {network.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={6} md="auto">
                    <Form.Select
                      size="sm"
                      onChange={e =>
                        setFilters({ ...filters, manager_id: e.target.value })
                      }
                    >
                      <option value="">All Managers</option>
                      {users.map(manager => (
                        <option key={manager.id} value={manager.id}>
                          {manager.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col xs={6} md="auto">
                    <Form.Select
                      size="sm"
                      onChange={e =>
                        setFilters({
                          ...filters,
                          project_lead_id: e.target.value
                        })
                      }
                    >
                      <option value="">All Project Leads</option>
                      {users.map(project_lead => (
                        <option key={project_lead.id} value={project_lead.id}>
                          {project_lead.name}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>{' '}
                  <Col
                    xs="auto"
                    className="z-index-2"
                    style={{ maxWidth: '9em' }}
                  >
                    {toggleListingButtonValue == LISTING_TYPE.ACTIVE && (
                      <ReactDatePicker
                        selected={date}
                        onChange={date => setDate(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control pb-0 pt-1"
                        placeholderText="Select Date"
                        calendarStartDay={1}
                      />
                    )}
                  </Col>
                  <Col xs="auto" className="ms-md-auto ms-lg-0">
                    <ButtonGroup>
                      {listingToggleButtons.map((button, index) => (
                        <ToggleButton
                          key={index}
                          id={`radio-${index}`}
                          type="radio"
                          className="mb-0"
                          variant={button.variant}
                          name="toggleBudgetOverviewListing"
                          value={button.value}
                          checked={toggleListingButtonValue === button.value}
                          onChange={e => {
                            return setToggleListingButtonValue(
                              e.currentTarget.value
                            );
                          }}
                          size="sm"
                        >
                          {button.label}
                        </ToggleButton>
                      ))}
                    </ButtonGroup>
                  </Col>
                </Row>
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    className: 'fs--1 mb-0 table'
                  }}
                  virtualRows
                  virtualRowsTableOffsetHeight="15rem"
                  apiCallInProcess={apiCallInProcess}
                  rowProps={row => {
                    return {
                      className: classNames({
                        'bg-soft-success':
                          row.original.goal_primary_current_cost >
                          row.original.goal_primary_total_target
                      })
                    };
                  }}
                  tableFooterClassName="table-secondary"
                />
                <AdvanceTableFooter
                  table
                  className="mt-3 mx-3"
                  rowCount={data.length}
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </AdvanceTableWrapper>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UpsertBudgetOverview
        showModal={showUpsertBudgetOverviewModal}
        setShowModal={setShowUpsertBudgetOverviewModal}
        currentBudgetOverviewId={currentBudgetOverviewId}
        setCurrentBudgetOverviewId={setCurrentBudgetOverviewId}
        loadlisting={() =>
          loadListing(
            listingToggleButtons.find(button =>
              button.value === toggleListingButtonValue ? button.url : null
            )
          )
        }
      />
    </>
  );
};

export default BudgetOverviews;
