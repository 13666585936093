import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper, {
  SelectBoxFilterMenu
} from 'components/common/advance-table/AdvanceTableWrapper';
import CallApi from 'components/common/custom-components/CallApi';
import LocalStorageTooltip from 'components/common/custom-components/LocalStorageTooltip';
import NameWithIcon from 'components/common/custom-components/NameWithIcon';
import IconButton from 'components/common/IconButton';
import useUrl from 'hooks/useUrl';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Card, Col, Row, ToggleButton } from 'react-bootstrap';
import { searchQueryKeywordsContext } from '../SearchQueryKeywords';
import ChangeCampaignAdgroupModal from './ChangeCampaignAdgroupModal';
import { actionButtons } from './constants';
import SearchQueryBulkSelect from './SearchQueryBulkSelect';
import StatusWithName from './StatusWithName';

const MainTable = () => {
  const [data, setData] = useState([]);
  const query = useUrl();
  const [apiCallInProcess, setApiCallInProcess] = useState(false);
  const [skipPageReset, setSkipPageReset] = useState(true);
  const [showChangeCampaignAdgroupModal, setShowChangeCampaignAdgroupModal] =
    useState(false);
  const { searchQueryContext, setSearchQueryContext } = useContext(
    searchQueryKeywordsContext
  );
  const [showRefreshSpinner, setShowRefreshSpinner] = useState(false);

  useEffect(() => {
    loadListing(true, false, true);
    getColumns();
  }, [query, searchQueryContext.refreshListing]);

  const loadListing = async (
    /** @type {boolean} */ showLoading,
    /** @type {Boolean} */ skipReset,
    /** @type {Boolean} */ showNotification = false
  ) => {
    if (showLoading) setApiCallInProcess(true);
    if (skipReset) setSkipPageReset(true);
    return CallApi.get(
      `/search-query-keywords?account_id=${query.get('account_id')}`,
      showNotification
    ).then(json => {
      setApiCallInProcess(false);
      if (json.status == 200) {
        setData(json.data);
        if (skipReset) setSkipPageReset(false);

        setSearchQueryContext({
          ...searchQueryContext,
          pagePermissions: json.pagePermissions
        });
        return true;
      }
    });
  };

  const changeValueForButtons = (index, actionValue) => {
    // This return is added @singhhrpreet; requires a demo to billy before implementing.
    return;
    const newData = [...data];
    newData[index].action = actionValue;
    setData(newData);
  };

  const ToggleButtons = ({ cell }) => {
    const [value, setValue] = useState(cell.row.original.action);

    return (
      <>
        {actionButtons.map((button, index) => {
          const [showSpinner, setShowSpinner] = useState(false);
          const [showExclamation, setShowExclamation] = useState(false);
          return (
            <ToggleButton
              key={index}
              type="radio"
              className="me-2"
              variant={button.variant}
              style={{ width: '130px' }}
              onClick={async () => {
                setSkipPageReset(true);
                setShowSpinner(true);
                setValue(button.value);
                if (await DecideKeyword(button.action, cell.row.original.id)) {
                  setShowSpinner(false);
                  setShowExclamation(false);
                  changeValueForButtons(cell.row.index, button.value);
                } else {
                  setShowSpinner(false);
                  setShowExclamation(true);
                }
              }}
              value={button.value}
              checked={value == button.value}
              disabled={button.value == value && showSpinner}
            >
              {button.label}
              {showSpinner && (
                <FontAwesomeIcon icon={'spinner'} spin className="ms-2" />
              )}
              {showExclamation && !showSpinner && button.value == value && (
                <FontAwesomeIcon
                  className="ms-2"
                  icon={'exclamation-triangle'}
                />
              )}
            </ToggleButton>
          );
        })}
      </>
    );
  };

  const columns = [
    {
      accessor: 'action',
      Header: 'Actions',
      headerProps: {
        className: 'text-center'
      },
      cellProps: {
        className: 'text-center'
      },
      Filter: SelectBoxFilterMenu,
      customOptions: actionButtons,
      Cell: ({ cell }) => <ToggleButtons cell={cell} />
    },
    {
      accessor: 'decided_by_user',
      Header: 'Decided By',
      Filter: SelectBoxFilterMenu,
      Cell: ({ cell }) => (
        <NameWithIcon
          name={cell.value}
          icon={cell.row.original.decided_by_user_avatar}
        />
      )
    },
    {
      accessor: 'keyword',
      Header: () => (
        <>
          Keyword{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_keyword" />
        </>
      ),
      Cell: ({ cell }) => (
        <a
          href={`${cell.row.original.result_check_url}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={'external-link-alt'} /> {cell.value}
        </a>
      )
    },
    {
      accessor: 'score',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'Score',
      Header: () => (
        <>
          Score{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_score" />
        </>
      ),
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic',
      Cell: ({ cell }) => cell.value + ' %'
    },
    {
      accessor: 'source',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'Source',
      Header: () => (
        <>
          Source{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_source" />
        </>
      )
    },
    {
      accessor: 'score_positive',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'P +',
      Header: () => (
        <>
          P +{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_score_positive" />
        </>
      ),
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic'
    },
    {
      accessor: 'score_negative',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'P -',
      Header: () => (
        <>
          P -{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_score_negative" />
        </>
      ),
      cellProps: {
        className: 'text-end'
      },
      sortType: 'basic'
    },
    {
      accessor: 'campaign',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'Campaign',
      Header: () => (
        <>
          Campaign{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_campaign" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.campaign_status}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'adgroup',
      Filter: SelectBoxFilterMenu,
      HeaderAsString: 'Ad Group',
      Header: () => (
        <>
          Ad Group{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_adgroup" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.adgroup_status}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'can_change_adgroup',
      Header: () => (
        <>
          Change Adgroup{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_adgroup_changer" />
        </>
      ),
      Cell: ({ row }) => (
        <Button
          variant="link"
          onClick={() => {
            setSearchQueryContext({
              ...searchQueryContext,
              isBulkSelectedAndUpdating: false,
              adgroupChangeableKeyword: row.original
            });
            setShowChangeCampaignAdgroupModal(true);
          }}
          size="sm"
        >
          Edit Adgroup
        </Button>
      )
    },
    {
      accessor: 'new_campaign_name',
      Header: () => (
        <>
          New Campaign{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_new_campaign_name" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.new_campaign_status}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'new_adgroup_name',
      Header: () => (
        <>
          New Ad Group{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_new_adgroup_name" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.new_adgroup_status}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'campaign_name_originally',
      Header: () => (
        <>
          Campaign (originally){' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_campaign_name_originally" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.campaign_status_originally}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'adgroup_name_originally',
      Header: () => (
        <>
          Adgroup (originally){' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_adgroup_name_originally" />
        </>
      ),
      Cell: ({ cell }) => (
        <StatusWithName
          status={cell.row.original.adgroup_status_originally}
          name={cell.value}
        />
      )
    },
    {
      accessor: 'note',
      Header: () => (
        <>
          Note{' '}
          <LocalStorageTooltip identifier="table_search_query_keywords_column_note" />
        </>
      )
    }
  ];

  const getColumns = () =>
    data.length > 0
      ? columns.filter(column => Object.keys(data[0]).includes(column.accessor))
      : [];

  const DecideKeyword = async (action, keywordId) => {
    let payload = new FormData();

    payload.append('id', keywordId);
    payload.append('action', action);

    const response = await CallApi.put(
      `/search-query-keywords?type=deciding`,
      payload,
      false
    ).then(json => (json ? json.status == 200 : false));

    return response;
  };

  const ApproveKeywords = () => {
    CallApi.put(
      `/search-query-keywords?type=approving&account_id=${query.get(
        'account_id'
      )}`
    ).then(json => {
      if (json.status == 200) loadListing(true, false, true);
    });
  };

  const SearchInputRef = useRef();

  return (
    <Row>
      <Col>
        <Card>
          <Card.Body className="px-0">
            <AdvanceTableWrapper
              columns={useMemo(() => getColumns(), [data])}
              data={data}
              sortable={true}
              selection
              pagination
              renderRowSubComponent={false}
              skipPageReset={skipPageReset}
              skipSortByReset={skipPageReset}
            >
              <Row className="align-items-end g-2 row mb-3 px-3">
                <Col className="col-auto">
                  <AdvanceTableSearchBox
                    // @ts-ignore
                    ref={SearchInputRef}
                    table
                  />
                </Col>
                <Col xs="auto">
                  <Button
                    size="sm"
                    onClick={async () => {
                      setShowRefreshSpinner(true);
                      SearchInputRef?.current?.clearInput();
                      if (await loadListing(false, true, true)) {
                        setShowRefreshSpinner(false);
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      icon={showRefreshSpinner ? 'spinner' : 'retweet'}
                      spin={showRefreshSpinner}
                      className="me-1"
                    />
                    Refresh
                  </Button>
                </Col>
                <Col />
                <Col xs="auto">
                  <SearchQueryBulkSelect table data={data} setData={setData} />
                </Col>
                <Col className="col-auto">
                  <IconButton
                    onClick={() => ApproveKeywords()}
                    icon="check"
                    size="sm"
                    variant="success"
                    disabled={!searchQueryContext.pagePermissions.can?.approve}
                  >
                    Approve
                  </IconButton>
                </Col>
              </Row>{' '}
              <AdvanceTable
                // @ts-ignore
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  bordered: true,
                  striped: true,
                  className: 'fs--1 mb-0'
                }}
                apiCallInProcess={apiCallInProcess}
                virtualRows
                virtualRowsTableOffsetHeight="26rem"
              />
              <AdvanceTableFooter
                // @ts-ignore
                table
                className="mt-3 mx-3"
                rowCount={data.length}
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </AdvanceTableWrapper>
          </Card.Body>
        </Card>
        <ChangeCampaignAdgroupModal
          show={showChangeCampaignAdgroupModal}
          setShow={setShowChangeCampaignAdgroupModal}
        />
      </Col>
    </Row>
  );
};
export default MainTable;
