/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize, getBooleanValueLabel } from 'helpers/utils';
import { isBoolean, isFunction } from 'lodash';
import { isString } from 'lodash';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useMemo } from 'react';
import { Card, Dropdown, Form } from 'react-bootstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useExpanded,
  useRowState,
  useFilters,
  useBlockLayout,
  useResizeColumns
} from 'react-table';
import FalconCardHeader from '../FalconCardHeader';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, variant, ...rest }, ref) => {
    const defaultRef = React.useRef();

    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <FontAwesomeIcon
          icon={rest.checked ? 'star' : ['far', 'star']}
          className={classNames(
            'text-warning',
            'cursor-pointer',
            variant == 'star' ? 'd-block' : 'd-none'
          )}
          onClick={() => resolvedRef.current.click()}
        />
        <Form.Check
          type="checkbox"
          className={classNames(
            'form-check fs-0 mb-0 d-flex align-items-center',
            variant == 'checkbox' ? 'd-block' : 'd-none'
          )}
        >
          <Form.Check.Input type="checkbox" ref={resolvedRef} {...rest} />
        </Form.Check>
      </>
    );
  }
);

export const SelectBoxFilterMenu = ({
  column: {
    Header,
    HeaderAsString,
    filterValue,
    setFilter,
    preFilteredRows,
    customOptions,
    id
  },
  state: { filters }
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      options.add(row.values[id]);
    });
    return customOptions || [...options.values()].sort();
  }, [id, preFilteredRows]);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Dropdown as="span" onToggle={handleToggle}>
      <Dropdown.Toggle bsPrefix="toggle" as={'a'}>
        <FontAwesomeIcon
          title={`Filter ${HeaderAsString || Header} Column`}
          icon="filter"
          className={classNames(
            {
              'text-info': filters.find(filter => id == filter.id)
            },
            'me-1 pe-1'
          )}
          style={{ cursor: 'pointer' }}
        />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end">
        <Card
          className=" dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem', minWidth: '15rem' }}
        >
          <FalconCardHeader
            className="card-header border-bottom py-2 px-3"
            title={`Filter ${HeaderAsString || Header}`}
            titleTag="h6"
            light={false}
          />
          <Card.Body className="py-2 px-3">
            <select
              className="form-select form-select-sm"
              id=""
              value={filterValue}
              onChange={e => {
                setFilter(e.target.value || undefined);
              }}
              placeholder="select option"
            >
              <option value="">All</option>
              {options.map((option, i) => {
                // referencing dot notation values in case of custom options
                return (
                  <option key={i} value={option?.value || option}>
                    {option?.label || isBoolean(option)
                      ? getBooleanValueLabel(option)
                      : option}
                  </option>
                );
              })}
            </select>
          </Card.Body>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const AdvanceTableWrapper = forwardRef(
  (
    {
      children,
      columns,
      resizable = false,
      data,
      sortable,
      selection,
      selectionColumnWidth,
      selectionVariant = 'checkbox',
      pagination,
      perPage = 100,
      useTableFooter = false,
      rowSelectionProps = {
        onChange: (event, tableCell, selectedFlatRows) => {},
        style: '',
        checked: false,
        title: ''
      },
      onRowSelect = selectedRows => {},
      renderRowSubComponent,
      skipPageReset = false,
      skipSortByReset = false,
      onBulkSelectBoxChange = row => {},
      showBulkSelectRowCheckbox = true,
      sortByOptions = useMemo(() => [], [])
    },
    ref
  ) => {
    const defaultColumn = React.useMemo(
      () => ({
        Filter: ({
          column: { Header, filterValue, setFilter, preFilteredRows, id }
        }) => <span></span>,
        canFilter: false
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      headers,
      page,
      prepareRow,
      footerGroups,
      canPreviousPage,
      canNextPage,
      nextPage,
      previousPage,
      setPageSize,
      flatRows,
      selectedFlatRows,
      toggleAllRowsSelected,
      gotoPage,
      pageCount,
      state: { expanded, pageIndex, pageSize, selectedRowIds, globalFilter },
      setGlobalFilter,
      setFilter
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        disableSortBy: !sortable,
        autoResetPage: !skipPageReset,
        autoResetSortBy: !skipSortByReset,
        initialState: {
          pageSize: pagination ? perPage : data.length,
          sortBy: sortByOptions
        },
        disableResizing: !resizable
      },
      ...[
        ...(resizable ? [useBlockLayout] : []),
        useResizeColumns,
        useFilters,
        useGlobalFilter,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect,
        useRowState,
        hooks => {
          if (selection) {
            hooks.visibleColumns.push(columns => [
              {
                id: 'selection',
                Header: ({ getToggleAllPageRowsSelectedProps }) => {
                  return (
                    <IndeterminateCheckbox
                      variant={selectionVariant}
                      className={!showBulkSelectRowCheckbox && 'd-none'}
                      {...getToggleAllPageRowsSelectedProps()}
                    />
                  );
                },
                headerProps: {
                  style: {
                    maxWidth: selectionColumnWidth
                  }
                },
                cellProps: {
                  style: {
                    maxWidth: selectionColumnWidth
                  }
                },
                Cell: ({ row, cell, toggleRowSelected }) => {
                  const checkBoxProps = {
                    ...rowSelectionProps,
                    onChange: event => {
                      toggleRowSelected(row.id, !row.isSelected);
                      rowSelectionProps.onChange(event, cell, selectedFlatRows);
                    }
                  };
                  return (
                    <div>
                      <IndeterminateCheckbox
                        variant={selectionVariant}
                        {...row.getToggleRowSelectedProps()}
                      />
                    </div>
                  );
                },
                canFilter: true,
                filter: (rows, columnIds, filterValue) => {
                  if (filterValue == 'checked') {
                    return rows.filter(row => row.isSelected);
                  }
                  return rows;
                },
                Footer: () => (selection ? 'All Rows' : null)
              },
              ...columns
            ]);
          }
        }
      ]
    );

    React.useEffect(() => {
      onRowSelect(selectedFlatRows);
    }, [onRowSelect, selectedFlatRows]);

    useImperativeHandle(ref, () => ({
      selectAllRows: () => toggleAllRowsSelected(true),
      deSelectAllRows: () => toggleAllRowsSelected(false),
      globalFilter: globalFilter,
      updateGlobalFilter: filterValue => setGlobalFilter(filterValue),
      resetGlobalFilterToLastQuery: () => setGlobalFilter(globalFilter)
    }));

    const resetGlobalFilterToLastQuery = () => setGlobalFilter(globalFilter);

    const recursiveMap = children => {
      return React.Children.map(children, child => {
        if (child.props?.children) {
          return React.cloneElement(child, {
            children: recursiveMap(child.props.children)
          });
        } else {
          if (child.props?.table) {
            return React.cloneElement(child, {
              ...child.props,
              getTableProps,
              getTableBodyProps,
              headerGroups,
              rows,
              headers,
              page,
              flatRows,
              prepareRow,
              footerGroups,
              canPreviousPage,
              canNextPage,
              nextPage,
              previousPage,
              gotoPage,
              pageCount,
              pageIndex,
              resetGlobalFilterToLastQuery,
              selectedRowIds,
              pageSize,
              setPageSize,
              globalFilter,
              selection,
              setGlobalFilter,
              useTableFooter,
              setFilter,
              selectedFlatRows,
              renderRowSubComponent
            });
          } else {
            return child;
          }
        }
      });
    };

    return (
      // <>
      //   {React.Children.map(children, child => {
      //     if (child.props.table) {
      //       return React.cloneElement(child, {
      //         ...child.props,
      //         getTableProps,
      //         headers,
      //         page,
      //         prepareRow,
      //         canPreviousPage,
      //         canNextPage,
      //         nextPage,
      //         previousPage,
      //         gotoPage,
      //         pageCount,
      //         pageIndex,
      //         selectedRowIds,
      //         pageSize,
      //         setPageSize
      //       });
      //     } else {
      //       return child;
      //     }
      //   })}
      // </>
      <>{recursiveMap(children)}</>
    );
  }
);

export default AdvanceTableWrapper;
